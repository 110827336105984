import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import PurchaseForm from "../components/Form/Purchase";

const Page = ({ data }) => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <SearchEngineOptimization
        title=""
        description=""
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section>
        <div className="absolute z-10 flex w-full items-center justify-between px-4 py-4 lg:px-10 lg:py-8">
          <Link to="/">
            <img
              src={data.logo.publicURL}
              alt="Accel Mortgage Logo"
              width={230}
            />
          </Link>

          <Link to="/request-rates/">
            <i className="fal fa-times text-xl text-black transition-all duration-300 ease-linear hover:text-primary-600"></i>
          </Link>
        </div>

        <div className="grid h-screen lg:grid-cols-12">
          <div className="hidden flex-col items-start justify-between bg-primary-50 py-12 lg:col-span-3 lg:col-start-1 lg:flex lg:px-10 lg:pt-32 lg:pb-12">
            <div>
              <p className="text-sm">
                We’re excited to start you on your path to a new home! First,
                we’ll ask you a few simple questions to understand your goals
                and then have you provide your contact information so we can
                reach out. If you’d like immediate assistance, call us at{" "}
                <a
                  href="tel:323-910-1070"
                  className="whitespace-nowrap text-primary-600 no-underline"
                >
                  (323) 910-1070
                </a>
                .
              </p>
            </div>
          </div>

          <div className="mt-14 py-8 px-4 lg:col-span-9 lg:col-end-13 lg:mt-0 lg:py-12 lg:px-20">
            <div className="relative h-full">
              <PurchaseForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    logo: file(relativePath: { eq: "Global/Nav/Logo.svg" }) {
      publicURL
    }
  }
`;
export default Page;
